.conditions {
  .col-1-4 {
    background: #000;
    color: #fff;
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 50px 16px 50px 50px;
    }
    h2 {
      font-size: 40px;
      margin-top: 0;
      text-transform: uppercase;
    }
    p {
      font-size: 18px;
      a {
        color: #fff;
      }
    }
  }
  .col-3-4 {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 33.33333%;
    height: 25vw;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    h3 {
      margin-bottom: 0;
      z-index: 5;
      position: relative;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
    }
    p {
      z-index: 5;
      position: relative;
      color: #fff;
      font-size: 18px;
      margin: 16px 0 0;
    }
    &:nth-child(1) {
      &:before {
        background-image: url("../img/conditions/1.jpg");
      }
      &:after {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
      }
    }
    &:nth-child(2) {
      &:before {
        background-image: url("../img/conditions/2.webp");
      }
    }
    &:nth-child(3) {
      &:before {
        background-image: url("../img/conditions/3.jpg");
      }
      &:after {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
      }
    }
    &:nth-child(4) {
      &:before {
        background-image: url("../img/conditions/10.webp");
      }
    }
    &:nth-child(5) {
      &:before {
        background-image: url("../img/conditions/5.jpg");
      }
      &:after {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
      }
    }
    &:nth-child(6) {
      &:before {
        background-image: url("../img/conditions/6.webp");
      }
    }
    &:nth-child(7) {
      &:before {
        background-image: url("../img/conditions/7.jpg");
      }
      &:after {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
      }
    }
    &:nth-child(8) {
      &:before {
        background-image: url("../img/conditions/8.jpg");
      }
    }
    &:nth-child(9) {
      &:before {
        background-image: url("../img/conditions/9.jpg");
      }
      &:after {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
      }
    }
    &:nth-child(10) {
      &:before {
        background-image: url("../img/conditions/10.jpg");
      }
      display: none;
    }
  }
}
