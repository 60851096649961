.ReactModal__Overlay {
  z-index: 2000;
  display: flex;
  align-items: center;
}

.ReactModal__Body--open {
  overflow: hidden;

  #root {
    overflow: hidden;
    height: 100vh;
  }
}

.ReactModal__Content {
  max-width: 980px;
  margin: auto;
  border: 0!important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
  border-radius: 0!important;
  top: initial!important;
  bottom: initial!important;
  padding: 0!important;
  max-height: 90vh;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  overflow-anchor: none;
  h3 {
    margin: 40px 0 20px;
    font-size: 28px;
    text-transform: uppercase;
    color: #000000;
  }
  .btn {
    margin: 0 auto;
    &-close {
      background: transparent;
      border: 0;
    }
  }
  .controls {
    margin: 20px 0 0;
    text-align: center;
  }
  .modal-body {
    overflow-y: auto;
    padding: 24px 30px;
    h2 {
      padding: 0;
    }
    .index__formWrapper,.wrap-form {
      margin: 0;
    }
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 50px 20px 30px;
    background: #D2233C;
    h3 {
      margin: 0;
      font-weight: 400;
      color: #fff;
    }
  }
}

@media screen and (max-width: 767px) {
  .ReactModal__Overlay {
    overflow-y: auto;
    display: block;
    overscroll-behavior: contain;
  }
  .ReactModal__Content {
    max-height: none;
    position: relative;
    left: 0 !important;
    right: 0 !important;
    overflow: hidden !important;
    .modal-body {
      overflow: hidden;
      min-height: calc(100vh - 75px);
    }
  }
}

.react-responsive-modal-modalCenter {
  font-family: "Futura PT", sans-serif;
  font-size: 18px;
  line-height: 1.3;
  color: #000;
  p, ul {
    font-size: 18px;
  }
}

.react-responsive-modal-overlay {
  z-index: 9000;
}
