/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 16px;
  top: 55px;
  @media screen and (min-width: 941px) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 2px!important;
  cursor: pointer;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 40px!important;
  width: 40px!important;
  top: 50px!important;
  right: 20px!important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000;
  width: 2px!important;
  height: 34px!important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100%!important;
  left: 0!important;
  top: 0!important;
  @media screen and (min-width: 941px) {
    display: block!important;
    position: static!important;
    height: initial!important;
    width: initial!important;
    transform: initial!important;
    .bm-menu {
      background: transparent;
      padding: 0;
      font-size: initial;
      overflow: visible;
    }
    .bm-cross-button {
      display: none;
    }
  }
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
