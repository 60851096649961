.list-vacancy {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-child(odd) {
      padding-right: 25px;
    }
    &:nth-child(even) {
      padding-left: 25px;
    }
    &:nth-child(5) {
      padding: 0;
      width: 100%;
    }
    p, ul, div {
      font-size: 22px;
    }
    ul {
      margin: 16px 0;
      li {
        &:before {
          margin: 9px 16px 0 0;
        }
      }
    }
  }
}
