.list-reviews {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-child(odd) {
      padding-right: 25px;
    }
    &:nth-child(even) {
      padding-left: 25px;
    }
    h3 {
      margin-bottom: 20px;
      text-align: left;
    }
    p, ul, div {
      font-size: 22px;
    }
    ul {
      margin: 16px 0;
      li {
        margin-bottom: 40px;
        font-weight: 400;
        &:before {
          margin: 9px 16px 0 0;
        }
      }
    }
  }
}
