.Dropdown-control {
  background: transparent;
  border: 0;
  color: #D2233C;
  font-size: 18px;
  padding: 0 40px 0 10px;
  top: 0;
  text-transform: uppercase;
  text-align: right;
  &:hover {
    box-shadow: none;
  }
  .Dropdown-arrow {
    width: 13px;
    height: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-0.5' x2='8.80964' y2='-0.5' transform='matrix(0.698995 0.715126 -0.698995 0.715126 0 1.70001)' stroke='%23D2233C'/%3E%3Cline y1='-0.5' x2='9.78848' y2='-0.5' transform='matrix(-0.698995 0.715126 -0.698995 -0.715126 13 1)' stroke='%23D2233C'/%3E%3C/svg%3E%0A");
    background-size: 13px 7px;
    display: inline-block;
    border: 0;
    top: 9px;
  }
}

.Dropdown-root {
  min-width: 155px;
}

.Dropdown-option {
  &:hover {
    background-color: #ffd4da;
  }
  &.is-selected {
    background-color: #d2233b;
    color: #fff;
  }
}
