* {
  box-sizing: border-box;
}

body {
  background: #F7F4FA;
}

html, body, #root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1820px;
  margin: 0 auto;
  display: block;
  padding: 0 50px
}

.row {
  display: flex;
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.col-1, .col-2 {
  display: inline-flex;
}

.col-1 {
  width: 50%;
}

.col-2 {
  width: 50%;
}

.col-1-4 {
  width: 25%;
}

.col-3-4 {
  width: 75%;
}

.inline-block {
  display: inline-block;
}
