.box-red {
  background: #D2233C;
  color: #fff;
  padding: 80px 0 100px;
  h2 {
    font-size: 48px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 101px;
  }
}

.scroll-container {
  background: transparent!important;
}
