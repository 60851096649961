html, body {
  font-family: "Futura PT", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #000;
}

h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 400;
}

h2 {
  font-weight: 400;
}

p, ul {
  font-size: 24px;
  margin: 16px 0;
  font-weight: 400;
}

b {
  font-weight: bold!important;
}

.block {
  display: block;
}

.text-red {
  color: #D2233C;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
